import { FC, createContext, useMemo } from 'react';

export type ConfigType = {
  appCode?: string;
  googleMapApiKey?: string;
  googleAdsId?: string;
  mdcApiHost?: string;
  importServiceApiHost?: string;
  cmsApiHost?: string;
  mdcFullApiUrl?: string;
  cmsFullApiUrl?: string;
  mdcGraphQlUrl?: string;
  cmsGraphQlUrl?: string;
  auth0Audience?: string;
  auth0ClientId?: string;
  auth0ChangePasswordApiUrl?: string;
  auth0Domain?: string;
  auth0LogoutReturnTo?: string;
  stripePublicKey?: string;
  googleAnalyticId?: string;
  sentryDns?: string;
  sentryEnv?: string;
  sentryEnabled?: boolean;
  sentryTracesSampleRate?: string;
  sentryAuthToken?: string;
  sentryEnabledReply?: boolean;
  theboatAppBlogUrl?: string;
  theboatDbBlogUrl?: string;
  meiliSearchApiKey?: string;
  meiliSearchHostUrl?: string;
  zohoSalesIqWidgetCode?: string;
  mdcLandingUrl?: string;
  theboatAppUrl?: string;
  theboatLandingUrl?: string;
  mdcAccountUrl?: string;
  theboatDbUrl?: string;
  cloudFlareSkipToken?: string;
  zohoRumKey?: string;
  recaptchaKey?: string;
  novuBackendUrl?: string;
  novuSocketUrl?: string;
  novuAppIdentifier?: string;
  novuAlertWorkflowId?: string;
  freePlanId?: string;
};

export const ConfigurationContext = createContext<ConfigType>({});

export const ConfigProvider: FC<{ values: ConfigType; children: React.ReactNode }> = ({ children, values }) => {
  const ConfigContextValue = useMemo<ConfigType>(() => values, [values]);

  return <ConfigurationContext.Provider value={ConfigContextValue}>{children}</ConfigurationContext.Provider>;
};

export default ConfigProvider;
